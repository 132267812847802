import { thumbnail } from "~lib/utils";
/**
 * Layout enum. Always refer by key, not number.
 */
export const IMAGE_LAYOUT_OPTIONS = {
  none: 0,
  alwaysLarge: 1, // @TODO - Not implemented yet
  alwaysSmall: 2,
  desktopLarge: 3,
  desktopHide: 4,
};

export function ResponsiveImage({ image_url, imageLayout, className, ...attributes }) {
  // Fastly params for image resizing
  // The "crop" param will crop the image to the specified aspect ratio.
  // crop's "smart" param will center the image on the most interesting part such as a face or a horizon.
  // The "optimize" param will moderately compress the image. Ignored on GIFs.
  const square = { crop: "1.1", optimize: "smart" };
  const wide = { crop: "16.9", optimize: "smart" };

  // Small images should be 80px wide and square
  if (imageLayout === IMAGE_LAYOUT_OPTIONS.alwaysSmall) {
    return (
      <img
        className={className}
        src={image_url}
        srcSet={`${thumbnail(image_url, 80, square)} 80w, ${thumbnail(image_url, 120, square)} 120w, ${thumbnail(image_url, 160, square)} 160w`}
        sizes="80px"
        {...attributes}
      />
    );
  }

  // Everything should render at ~280px wide above 900px
  return (
    <picture className={className}>
      <source
        media="(min-width: 900px)"
        srcSet={`${thumbnail(image_url, 280, wide)} 280w, ${thumbnail(image_url, 420, wide)} 420w, ${thumbnail(image_url, 560, wide)} 560w`}
        // Try to force 1.5x image on 2x screens
        sizes="(min-resolution: 1dppx) 140px, (min-resolution: 1.5ppx) 280px"
      />
      <img
        src={image_url}
        srcSet={`${thumbnail(image_url, 80, square)} 80w, ${thumbnail(image_url, 120, square)} 120w, ${thumbnail(image_url, 160, square)} 160w`}
        sizes="80px"
        {...attributes}
      />
    </picture>
  );
}
